import React from "react"
import PropTypes from "prop-types"
import Img from 'gatsby-image'
import { Spring } from 'react-spring/renderprops'
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import Archive from "./archive"
import "./layout.css"

const MainLayout = styled.main`
  background: #d0fef5;
  max-width: 90%;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
`

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        file(relativePath:{
          regex: "/bg/"
        }) {
        childImageSharp {
          fluid(maxWidth:1000) {
            ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Spring 
          from={{height: location.pathname === '/' ? 100 : 200}} 
          to={{height: location.pathname === '/' ? 200 : 100}}
        >
          {styles => (
            <div style={{overflow: 'hidden', ...styles}}>
              <Img fluid={data.file.childImageSharp.fluid} />
            </div>
          )}  
        </Spring>
        {/*location.pathname === '/' &&
          
          */}<MainLayout>
          <div>{children}</div>
          <Archive />
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </MainLayout>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {}
}

export default Layout
